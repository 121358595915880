import React from 'react';
import './podcastList.scss';
import { useTranslation } from 'react-i18next';

function PodcastList() {
  const { t } = useTranslation();
  return (
    <div className="row podcastlist flex">
      <div className="col-md-12 col-12 flex">
        <h2 className="podcastlistHeading2">
          {t('podcast.section4.heading.part1')}
          <span className="specific">{t('podcast.section4.heading.part2')}</span>
        </h2>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-8efca165501aaed2a84e521a3f8&v=1704183913"
            title="Podcast#14"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#14 Quo Vadis Social Media - Teil 2</h4>
            <p>
              <strong>
                Herzlich willkommen zu einer neuen spannenden Episode von &quot;Quo Vadis Social
                Media?&quot; in unserem Podcast &quot;Digitalisierung. Positionierung.
                Sichtbarkeit&quot;!
              </strong>
            </p>
            <p>
              Das Konzept dieser Folge ist, dass ich mich mit unserem Social Media Manager Niklas
              über die aktuellen Geschehnisse im Bereich Socialmedia unterhalte, bewerte und mal
              auch ein wenig drumherum gucke.
            </p>
            <p>Dieses Mal haben wir uns unter anderem folgenden Themen gewidmet:</p>
            <ul>
              <li>
                <p>Start von Threads in der EU</p>
              </li>
              <li>
                <p>Welcher Content ist auf LinkedIn mittlerweile angesagt?</p>
              </li>
              <li>
                <p>Welches Netzwerk hat die Nase vorn?</p>
              </li>
              <li>
                <p>Was gibt es an &quot;neuen&quot; Netzwerken?</p>
              </li>
              <li>
                <p>Was zählt wirklich im Netzwerk?</p>
              </li>
            </ul>
            <p>Also, viel Spaß beim reinhören und lasst uns gerne wissen, wie ihr das fandet!</p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-00be105ca2b9787991d05b752e2&v=1704183913"
            title="Podcast#13"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#13 Interviewfolge René Grendel</h4>
            <p>
              In dieser Podcast-Folge utnerhalte ich mich mit René Grendel. Dem Mitarbeitermagnet.
            </p>
            <p>
              René hat sehr spannende Ansätze über die Möglichkeiten, wie man sich als Arbeitgeber
              intern wie extern positionieren kann, um dabei positiv wahrgenommen zu werden von
              seinen potenziellen Bewerbern und Mitarbeitern.{' '}
            </p>
            <p>Gleichzeitig hat er eine ganz eigene Sicht auf Bewertungen in Portalen.</p>
            <p />
            <p>
              Wenn ihr mehr zu René wissen wollt, sucht ihn am besten auf LinkedIn auf oder hört in
              seinen Podcast &quot;René Grendel - Mitarbeiterbindung DIE SUPERKRAFT&quot; rein. Zu
              finden auf allen gängigen Plattformen!
            </p>
            <p>Hier noch ein paar Links, um euch die Suche zu erleichtern:</p>
            <p>
              Instagram:{' '}
              <a
                href="https://www.instagram.com/der_mitarbeitermagnet/"
                rel="noopener noreferrer nofollow"
              >
                https://www.instagram.com/der_mitarbeitermagnet/
              </a>
            </p>
            <p>
              LinkedIn:{' '}
              <a
                href="https://www.linkedin.com/in/mitarbeitermagnet?miniProfileUrn=urn%3Ali%3Afs"
                rel="noopener noreferrer nofollow"
              >
                https://www.linkedin.com/in/mitarbeitermagnet
              </a>
            </p>
            <p>
              Seine Homepage:{' '}
              <a href="https://rene-grendel.de/" rel="noopener noreferrer nofollow">
                https://rene-grendel.de/
              </a>
            </p>
            <p>
              Der Podcast auf Spotify:{' '}
              <a
                href="https://open.spotify.com/show/77WZzpS87e971G6hXugnNx"
                rel="noopener noreferrer nofollow"
              >
                https://open.spotify.com/show/77WZzpS87e971G6hXugnNx
              </a>
            </p>
            <br />
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-e1b39a59365f8823e7aca70c09a&v=1704183913"
            title="Podcast#12"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#12 Quo Vadis Social Media - Teil 1 </h4>
            <p>
              <strong>
                Herzlich willkommen zur spannenden ersten Episode von &quot;Quo Vadis Social
                Media?&quot; in unserem Podcast &quot; Digitalisierung. Positionierung. Sichtbarkeit
                &quot;!
              </strong>
            </p>
            <p>
              In dieser Episode tauchst Du mit unserem Social Media Manager Niklas und mir in die
              pulsierenden Strömungen der Online-Kommunikation ein. Gemeinsam erkunden wir die
              aktuellen Trends und Themen, die in den sozialen Netzwerken für Aufsehen sorgen.
              Niklas, ein Virtuose der digitalen Social Media Welt, führt uns durch eine
              facettenreiche Landschaft aus viralen Phänomenen, aufstrebenden Plattformen und den
              neuesten Strategien im Social Media Marketing.
            </p>
            <p>
              Aber wir schauen nicht nur auf das Hier und Jetzt. Mit einem scharfen Auge für
              zukünftige Entwicklungen diskutieren wir auch, wie sich soziale Medien
              weiterentwickeln könnten. Welche technologischen Innovationen stehen vor der Tür? Wie
              werden sich unsere Interaktionen in diesen digitalen Räumen verändern?
            </p>
            <p>
              &quot;Quo Vadis Social Media?&quot; wird ein fester Bestandteil unseres Podcasts
              &quot;Digitalisierung. Positionierung. Sichtbarkeit&quot;. Es ist eine Reise in das
              Herz der digitalen Kommunikation, begleitet von tiefgründigen Analysen, spannenden
              Einblicken und einer Prise Humor. Schalte ein und werde Teil unserer Community, die
              gemeinsam die Zukunft der sozialen Medien erforscht. Entdecke mit uns, wohin die Reise
              geht!
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-98701e323b7c318d757660ead3&v=1699448582"
            title="Podcast#11"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#11 Influencer Marketing</h4>
            <p>
              {' '}
              In dieser Podcast-Folge tauchen wir tief in die Welt des Influencer-Marketings ein.
              Ich erkläre, was Influencer-Marketing ist und warum es in der heutigen Zeit so
              entscheidend ist. Gemeinsam werfen wir einen Blick auf die historische Entwicklung
              dieser Marketingstrategie und wie sie sich von den Anfängen des Internets bis hin zu
              den sozialen Medien entwickelt hat.
            </p>
            <p>
              Ein Schwerpunkt liegt auf der Rolle der Influencer selbst, wie sie ihre Zielgruppen
              aufbauen und pflegen und warum Authentizität und Glaubwürdigkeit so wichtig sind. Wir
              diskutieren auch die verschiedenen Faktoren und Indikatoren, die verwendet werden
              können, um die Authentizität von Influencern zu bewerten.
            </p>
            <p>
              Natürlich sprechen wir auch über den Erfolg von Influencer-Marketingkampagnen und
              welche Leistungsindikatoren dabei eine Rolle spielen. Von Reichweite über Engagement
              bis hin zu Umsatz und Content-Qualität - wir decken alles ab.
            </p>
            <p>
              Aber das ist noch nicht alles! Wir werfen auch einen Blick auf die rechtlichen Aspekte
              des Influencer-Marketings, den Umgang mit negativem Feedback und Shitstorms, und wie
              man Betrug und Fake-Followern begegnet.
            </p>
            <p>
              Abgerundet wird die Podcast-Folge mit wertvollen Tipps für Unternehmen, die in das
              Influencer-Marketing einsteigen möchten, und Best Practices für Influencer selbst.
              Authentizität, Transparenz und professionelle Zusammenarbeit stehen hier im
              Mittelpunkt.
            </p>
            <p>
              Begleite mich in dieser spannenden Podcast-Folge, um einen tiefen Einblick in die Welt
              des Influencer-Marketings zu gewinnen und zu erfahren, wie du diese Strategie effektiv
              nutzen kannst.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-01481cbe40d90846c6e3867e0af8&v=1698223587"
            title="Podcast#10"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#10 Brands und Brandbuilding</h4>
            <p>
              {' '}
              Du willst eine eigene Brand aufbauen, weißt aber nicht wie das geht? Dann ist das der
              richtige Podcast für Dich!
            </p>
            <p>
              Ich kläre hier klar auf, welche Dinge Du im Vorfeld für Dich klären musst. Welche Art
              von Brand möchtest du erstellen? Was sind die Unterschiede? Was ist einfacher?
            </p>
            <p>
              Schließlich gehen wir noch einmal alles durch was es an Methoden und Dingen gibt, die
              ein Brandbuilding erleichtern und Dir eine klare Struktur an die Hand geben, mit der
              du deine Brand direkt aufbauen kannst.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-94995ecbba5f1c001815670d6d&v=1698223587"
            title="Podcast#9"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#9 BigData und Social Media</h4>
            <p>
              {' '}
              In der heutigen Folge gehe ich näher auf BigData ein. Wodurch definiert es sich
              eigentlich, was macht es aus? Was sind die großen Vs von BigData?
            </p>
            <p>Wie verhält es sich im Marketing und was kann man damit eigentlich alles machen?</p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-44a63a1043b25856fa7295c13758&v=1698223587"
            title="Podcast#8"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#8 Interviewfolge - Marc Torke</h4>
            <p>
              {' '}
              In dieser Folge darf ich meine Fragen dem großartigen Marc Torke stellen. Marc ist
              schon länger im Social Media und Marketing Bereich unterwegs und weiß definitiv wovon
              er spricht. Er war früher schon oft hinter dem Mikro, als er noch Radiomoderator war.
              Er ist mit seiner Agentur aktuell zu den TOP 100 Agenturen für LinkedIn gewählt
              worden. Es lohnt sich also definitiv hier genauer zuzuhören, was er alles so zu sagen
              hat.
            </p>
            <p>Damit ihr ihn jetzt nicht lange suchen müsst, hier seine Kontaktmöglichkeiten:</p>
            <p>Marc Torke - Die Social Media Agentur:</p>
            <p>
              <a href="https://marctorke.de">https://marctorke.de/</a>
            </p>
            <p>
              Auf LinkedIn:{' '}
              <a href="https://www.linkedin.com/in/marctorke">
                https://www.linkedin.com/in/marctorke
              </a>
            </p>
            <p>
              Auf Youtube:{' '}
              <a href="https://www.youtube.com/channel/UCjTjNWvw_Ls6zCSHWyHplpw">
                https://www.youtube.com/channel/UCjTjNWvw_Ls6zCSHWyHplpw
              </a>
            </p>
            <p>
              Auf Facebook:{' '}
              <a href="https://www.facebook.com/diesocialmediaagentur">
                https://www.facebook.com/diesocialmediaagentur
              </a>
            </p>
            <br />
            <p>
              Flowmingo: <a href="https://flowmingo.de/">https://flowmingo.de/</a>
            </p>
            <br />
            <p>
              Auf Facebook:{' '}
              <a href="https://www.facebook.com/flowmingo.de">
                https://www.facebook.com/flowmingo.de
              </a>
            </p>
            <p>
              Auf Instagram:{' '}
              <a href="https://instagram.com/flowmingo.de?igshid=MzRlODBiNWFlZA==">
                https://instagram.com/flowmingo.de?igshid=MzRlODBiNWFlZA==
              </a>
            </p>
            <p>
              Auf TikTok:{' '}
              <a href="https://www.tiktok.com/@flowmingo.de">
                https://www.tiktok.com/@flowmingo.de
              </a>
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-c1fb2aaa4e55f8ba98be75bbaad&v=1698223587"
            title="Podcast#7"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#7 SEO Tools</h4>
            <p>
              {' '}
              Heute gehe ich auf die 3 großen Platzhirsche ein. SE Ranking, Semrush und Ahrefs.
            </p>
            <p>
              {' '}
              Wo liegen die Stärken? Wo liegen die Schwächen? Welche Besonderheiten haben die
              einzelnen Plattformen? All das klären wir hier in dieser Folge!
            </p>
            <p>
              {' '}
              Hier noch wie im Podcast angesprochen eine Übersicht über die einzelnen Details in
              verschiedenen Punkten von den angesprochenen Tools. Ganz am Ende findet ihr noch
              direkte Links zu den einzelnen Produkten!
            </p>
            <p>
              SE Ranking: <a href="https://seranking.com/">https://seranking.com/</a>
            </p>

            <p>
              SEMrush: <a href="https://www.semrush.com/">https://www.semrush.com/</a>
            </p>

            <p>
              ahrefs: <a href="https://ahrefs.com/">https://ahrefs.com/</a>
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-12176a614aa138bae798723e4ee8&v=1695448921"
            title="Podcast#6"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#6 Digitales Potenzial</h4>
            <p>
              {' '}
              In dieser Episode tauchen wir tief in das Potenzial der Digitalisierung ein. Wir
              erkennen, dass die digitale Reise weit über das bloße &quot;Weg vom Papier&quot;
              hinausgeht. Es geht darum, Prozesse lebendig zu gestalten, klare Strukturen aufzubauen
              und die Arbeit durch Automatisierung nicht nur effizienter, sondern auch sicherer zu
              machen. Wir sprechen über die Bedeutung des digitalen Marketings, von der Nutzung von
              Online-Strategien bis hin zur Suchmaschinenoptimierung, und wie Unternehmen durch
              E-Commerce wachsen können. Dabei dürfen wir natürlich die Transformation interner
              Prozesse und die Entwicklung innovativer Produkte nicht vergessen, die an die
              Bedürfnisse unserer digitalen Zielgruppe angepasst sind. Wir diskutieren, wie wichtig
              die Kundenkommunikation in dieser Ära ist und warum Tools wie Chatbots oder eine
              aktive Präsenz in den sozialen Medien entscheidend sind. Ein weiterer Schwerpunkt
              liegt auf der Online-Präsenz: Wie gestaltet man eine benutzerfreundliche Website?
              Warum ist SEO so wichtig? Wie können wir unsere Inhalte optimal gestalten und
              präsentieren? Dabei dürfen wir den Datenschutz und die Cybersicherheit nicht
              vernachlässigen. Abschließend sprechen wir über die Chancen, die sich durch die
              digitale Sichtbarkeit ergeben, sowohl für Unternehmen als auch für das Tracking von
              Besucherverhalten, und wie all diese Maßnahmen letztlich zu einem langfristigen Erfolg
              beitragen können. Begleitet mich auf dieser spannenden Reise durch die Welt der
              Digitalisierung!
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-12 leftBox">
          <iframe
            className="podcastIframe"
            title="Podcast#5"
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-6e061c9d7932b76d41d2da5f1e8&v=1695448921"
          />
        </div>
        <div className="col-md-8 col-12 rightBox">
          <div>
            <h4 className="bold">#5 Interviewfolge - Sirit Coeppicus</h4>
            <p>
              {' '}
              Da ist sie. Die erste Interviewfolge! Heute im Interview die einzigartige, die
              wunderbare, die phänomenale Sirit Coeppicus! Sirit ist ihres Zeichens eine echte
              Netzwerkerin, Texterin, Social Media Expertin und wunderbare Person. Wir haben uns im
              Coworking Space von worqs Aachen getroffen und nach ein paar technischen
              Schwierigkeiten dann am Ende unter freiem Himmel mit Vogelgezwitscher das Interview
              aufgenommen.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-12 leftBox">
          <iframe
            className="podcastIframe"
            title="Podcast#4"
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-ba0cf20f389f9442871c1e886&v=1695448921"
          />
        </div>
        <div className="col-md-8 col-12 rightBox">
          <div>
            <h4 className="bold">#4 KI & SEO - was macht die KI eigentlich?</h4>
            <p>
              {' '}
              In dieser Folge sprechen wir über die Schnittstelle von KI und SEO, insbesondere über
              das KI-Modell GPT. GPT wurde mit einem enormen Datensatz trainiert, von Büchern bis zu
              sozialen Medien, und kann kohärent und relevant auf Anfragen antworten. Es kann seinen
              Schreibstil an verschiedene Anforderungen anpassen, von formal bis locker, und findet
              Anwendungen in Bereichen wie Content-Erstellung und Kundensupport. Trotz seiner
              Fähigkeiten besitzt GPT keine menschliche Intelligenz. Es verarbeitet Texte basierend
              auf statistischen Mustern und verwendet &quot;Tokens&quot; zur Textgenerierung. Seine
              &quot;Intelligenz&quot; ist jedoch begrenzt: Es hat kein tiefes Verständnis für
              Konzepte und sein Wissen endet im September 2021. Daher ist es nicht immer aktuell und
              sollte mit Vorsicht verwendet werden, insbesondere in ethischen oder
              datenschutzrelevanten Bereichen. Im Bereich SEO kann GPT nützlich sein, um Textinhalte
              effizient zu generieren und SEO-Best Practices zu befolgen.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-12 leftBox">
          <iframe
            className="podcastIframe"
            title="Podcast#3"
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-e918ae54620b5cbc44c04e79e58&v=1695448921"
          />
        </div>
        <div className="col-md-8 col-12 rightBox">
          <div>
            <h4 className="bold">#3 Sichtbarkeit - Das entscheidende Phänomen</h4>
            <p>
              {' '}
              In dieser Folge nehme ich Euch mit auf eine Reise durch die facettenreiche Welt der
              Digitalisierung. Vom Wandel im Büroalltag bis zu großen Umbrüchen im E-Commerce – die
              Digitalisierung prägt, wie wir arbeiten, lernen und kommunizieren.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-12 leftBox">
          <iframe
            className="podcastIframe"
            title="Podcast#2"
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-46936f1c85c166066918bd97258&v=1695448295"
          />
        </div>
        <div className="col-md-8 col-12 rightBox">
          <div>
            <h4 className="bold">#2 - Positionierung - Wen und wo eigentlich</h4>
            <p>
              {' '}
              In dieser Folge meines Podcasts &quot;Digitalisierung. Positionierung.
              Sichtbarkeit.&quot; spreche ich darüber, wie man sich in der digitalen Ära erfolgreich
              positioniert, sei es auf Social Media, in Suchergebnissen oder auch offline. Ich
              betone, dass eine kohärente und authentische Online-Präsenz, gepaart mit Konsistenz,
              der Schlüssel zur Positionierung ist. Dabei nenne ich erfolgreiche Marken wie Apple
              und Nike als Beispiele für Konsistenz in Branding-Strategien. Ich gehe auch auf die
              Wichtigkeit von SEO ein, um in Suchergebnissen sichtbar zu sein. Darüber hinaus
              diskutiere ich die Relevanz der physischen Präsenz auf Messen und in Printmedien und
              wie ein starker visueller Eindruck eine Marke definieren kann. Ein Beispiel ist die
              Firma &quot;GreenDrive&quot; mit ihrem umweltfreundlichen Messestand. Zum Abschluss
              dieser Folge weise ich darauf hin, dass die Erkennung eigener Stärken und
              Leidenschaften der Schlüssel zur Positionierung ist und dass die digitale Welt
              ständige Anpassungen erfordert. Ich hoffe, ihr könnt einige wertvolle Erkenntnisse
              mitnehmen und freue mich auf zukünftige Diskussionen in kommenden Folgen! Es wäre
              toll, wenn ihr meinem Podcast eine 5-Sterne-Bewertung geben könntet.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-12 leftBox">
          <iframe
            className="podcastIframe"
            title="Podcast#1"
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-5a4d65d8a2ede78462d5d1ce478&v=1695448921"
          />
        </div>
        <div className="col-md-8 col-12 rightBox">
          <div>
            <h4 className="bold">#1 - Digitalisierung - Eine Begriffserklärung</h4>
            <p>
              {' '}
              In dieser Folge nehme ich Euch mit auf eine Reise durch die facettenreiche Welt der
              Digitalisierung. Vom Wandel im Büroalltag bis zu großen Umbrüchen im E-Commerce – die
              Digitalisierung prägt, wie wir arbeiten, lernen und kommunizieren.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-12 leftBox">
          <iframe
            className="podcastIframe"
            title="Episode#0"
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-fa53ec41bc069832e51adce31418&v=1695448295"
          />
        </div>
        <div className="col-md-8 col-12 rightBox">
          <div>
            <h4 className="bold">#0 - Intro</h4>
            <p>
              {' '}
              In dieser Folge nehme ich Euch mit auf eine Reise durch die facettenreiche Welt der
              Digitalisierung. Vom Wandel im Büroalltag bis zu großen Umbrüchen im E-Commerce – die
              Digitalisierung prägt, wie wir arbeiten, lernen und kommunizieren.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="license flex py-5">
        <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
          <img
            alt="Creative Commons Lizenzvertrag"
            src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png"
          />
        </a>
        <br />
        Dieses Werk ist lizenziert unter einer{' '}
        <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
          Creative Commons Namensnennung - Nicht kommerziell - Keine Bearbeitungen 4.0 International
          Lizenz
        </a>
      </div>
    </div>
  );
}

export default PodcastList;
